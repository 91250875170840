import React from "react";
import StatesNavBar from '../StatesFolder/StatesNavBar'
import '../../styles/counties.css'

export default function Counties () { 
    return(
        <div>
            <h1 className="title">Counties</h1>
            <StatesNavBar />
        </div>
    )
}