import React from 'react'

function Adot() {
    return (
        <div>
            <a href='https://azdot.gov/business/right-way-properties/existing-right-way-plans-index
' target={"_blank"} rel='noreferrer'>ROW Maps</a>
        </div>
    )
}

export default Adot
